/* Dashboard styles */

/* basic styles */
h4 {
    font-weight: 700;
}

/* header dashboard styles */
header.header-dashboard {
    width: 68px;
    height: 100vh;
    padding: 0 10px;
    position: fixed;
    z-index: 999;
}

header.header-dashboard nav {
    position: absolute;
    bottom: 0;
    width: 68px;
    margin-bottom: 40px;
}

header.header-dashboard nav ul {
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;
}

header.header-dashboard nav ul li {
    padding: 10px 0;
}

/* dashboard styles */
.dashboard {
    display: inline;
}

.dashboard .container {
    width: 100%;
    padding-left: 138px;
    float: left;
}

.dashboard-stats h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* settings styles */
.settings-section,
.dashboard-section {
    padding: 40px 0 40px 0;
}

/* cards styles */
.card-cover {
    display: inline-block;
}

.card-cover .card-listing {
    width: calc(12.5% - 17px);
    min-width: 150px;
    float: left;
    padding: 15px 0;
    text-align: center;
    border: 1px solid #d6d6d6;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    cursor: pointer;
}

.card-cover .card-listing picture {
    width: 100%;
    float: left;
}

.card-cover .card-listing picture img {
    max-width: 78px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.card-cover .card-listing h4 {
    font-size: 17px;
}

.card-cover .card-listing .newly-added {
    color: #056efb;
}

.card-cover .card-listing .active {
    color: #43a047;
}

/* other styles */
.dashboard-stats h4,
.stats-counter-box h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dashboard-section .card h4 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.stats-counter-box {
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.stats-counter-box .verticals {
    position: relative;
}

.stats-counter-box .verticals.six {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    padding: 15px;
}

.stats-counter-box .verticals.six p {
    margin-bottom: 0;
}

.stats-counter-box .counter {
    position: absolute;
    right: 0px;
    top: 28px;
    margin-right: 16px;
    color: #0670ff;
    font-weight: 900;
    font-size: 170%;
}

.create-invoice {
    padding: 40px 0;
    display: block;
    float: left;
    width: 100%;
}

.invoice-preview-section strong {
    font-weight: 700;
}