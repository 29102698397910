/* 
CSS Style sheet
Ver. 1.5
Author: Nishant Dogra
Initial built: 09-10-2017
Updated: 20-12-2021
*/

@media only screen and (max-width: 1999px) {

    /* write styles upto 1999px */
    .container {
        max-width: 1600px;
    }
}

@media only screen and (max-width: 1440px) {

    /* write styles upto 1440px */
    .container {
        max-width: 1260px;
    }
}

@media only screen and (max-width: 1200px) {

    /* write styles upto 1200px */
    .container {
        max-width: 96%;
    }

    .force-intro {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 768px) {

    /* write styles upto 768px */
    .container {
        max-width: 100%;
    }

    .button-full {
        width: 100%;
        margin-bottom: 20px;
        padding: 15px;
        margin-right: 0;
    }

    .one.vertical,
    .one.verticals,
    .two.verticals,
    .three.verticals,
    .four.verticals,
    .five.verticals,
    .six.verticals,
    .seven.verticals,
    .eight.verticals,
    .nine.verticals,
    .ten.verticals,
    .eleven.verticals,
    .twelve.verticals,
    .one-third.vertical,
    .two-thirds.vertical,
    .one-half.vertical {
        width: 100%;
        margin: 0;
    }

    .card,
    .card.half,
    .card.one-third,
    .card.one-fourth {
        display: block;
    }

    .force-intro {
        margin-top: 0;
    }
}

@media only screen and (max-width: 549px) {

    /* write styles upto 549px */
    h1,
    .h1 {
        font-size: 24px;
    }

    h2,
    .h2 {
        font-size: 22px;
    }

    h3,
    .h3 {
        font-size: 20px;
    }

    h4,
    .h4 {
        font-size: 18px;
    }

    h5,
    .h5 {
        font-size: 17px;
    }

    h6,
    .h6 {
        font-size: 16px;
    }

    .force-box .force-box-container {
        padding: 20px;
    }
}

@media only screen and (max-width: 419px) {
    /* write styles upto 419px */
}

@media only screen and (max-width: 360px) {
    /* write styles upto 360px */
}