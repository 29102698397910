/* 
CSS Style sheet
Ver. 1.5
Author: Nishant Dogra
Initial built: 09-10-2017
Updated: 20-12-2021
*/

/* Force Framework Roboto Font import */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

/* Force Framework - basics reset styles */
* {
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
    line-height: 1.2;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
    font-family: 'Roboto', sans-serif;
    color: #262626;
    position: relative;
}

code,
blockquote,
pre {
    font-family: "Courier New", Courier, monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 18px;
    font-weight: 300;
}

p {
    margin: 2px 0 12px;
    font-weight: 300;
}

img {
    border-style: none;
}

a {
    background-color: transparent;
    color: #1EAEDB;
}

a:hover,
a:focus,
a.active {
    color: #0FA0CE;
}

label>.label-body {
    display: inline-block;
    margin-left: 5px;
    font-weight: normal;
}

ul {
    list-style: circle inside;
}

ol {
    list-style: decimal inside;
}

ol,
ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 15px;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 15px 0 15px 25px;
    font-size: 90%;
}

code {
    padding: 2px 5px;
    margin: 0 2px;
    font-size: 90%;
    white-space: nowrap;
    background: #F1F1F1;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
}

pre>code {
    display: block;
    padding: 10px 16px;
    white-space: pre;
}

th,
td {
    padding: 12px 15px;
    text-align: left;
    border: none;
    border-spacing: 0;
    border-color: transparent;
}

th:first-child,
td:first-child {
    padding-left: 0;
}

th:last-child,
td:last-child {
    padding-right: 0;
}

input,
textarea,
select,
fieldset {
    margin-bottom: 5px;
}

pre,
blockquote,
dl,
figure,
table,
p,
ul,
ol,
form {
    margin-bottom: 15px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 30px;
    margin-bottom: 30px;
    border-width: 0;
    border-top: 1px solid #e6e6e6;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 16px;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

sub {
    bottom: -4px;
}

sup {
    top: -8px;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

fieldset {
    padding: 6px 12px 8px;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

details {
    display: block;
}

summary {
    display: list-item;
}

[hidden],
template {
    display: none;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea,
select {
    height: 42px;
    padding: 6px 10px;
    background-color: #fff;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea {
    min-height: 98px;
    padding-top: 6px;
    padding-bottom: 6px;
    overflow: auto;
    resize: none;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
textarea:focus,
select:focus {
    border: 1px solid #33C3F0;
    outline: 0;
}

label,
legend {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}

fieldset {
    padding: 0;
    border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline;
}

input:disabled {
    background: #f6f6f6;
    cursor: no-drop;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* Force Framework - header, footer, nav styles */
header {
    display: block;
}

footer {
    background: rgba(23, 12, 0, 0.8);
    color: #ffffff;
    padding: 60px 0 40px;
}

footer a {
    color: #f6923a;
    text-decoration: none;
}

footer a:hover,
footer a:focus {
    text-decoration: none;
    color: #f6923a;
    border-bottom: 1px dotted #f6923a;
}
footer nav a:last-child:after {
    content: "";
}

footer h3 {
    margin: 0;
}

footer .logo img {
    max-width: 128px;
}

nav strong {
    margin-right: 15px;
}

/* Force Framework - grid layout styles */
.container {
    position: relative;
    width: 100%;
    max-width: 94%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.container:after,
.horizontal:after {
    content: "";
    display: table;
    clear: both;
}

.container-fluid {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.horizontal {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}

.vertical,
.verticals {
    width: 100%;
    float: left;
    box-sizing: border-box;
}

.vertical,
.verticals {
    margin-left: 4%;
}

.vertical:first-child,
.verticals:first-child {
    margin-left: 0;
}

.one.vertical,
.one.verticals {
    width: 4.66666666667%;
}

.two.verticals {
    width: 13.3333333333%;
}

.three.verticals {
    width: 22%;
}

.four.verticals {
    width: 30.6666666667%;
}

.five.verticals {
    width: 39.3333333333%;
}

.six.verticals {
    width: 48%;
}

.seven.verticals {
    width: 56.6666666667%;
}

.eight.verticals {
    width: 65.3333333333%;
}

.nine.verticals {
    width: 74.0%;
}

.ten.verticals {
    width: 82.6666666667%;
}

.eleven.verticals {
    width: 91.3333333333%;
}

.twelve.verticals {
    width: 100%;
    margin-left: 0;
}

.one-third.vertical {
    width: 30.6666666667%;
}

.two-thirds.vertical {
    width: 65.3333333333%;
}

.one-half.vertical {
    width: 48%;
}

/* Force Framework - vertical offset styles */
.offset-by-one.vertical,
.offset-by-one.verticals {
    margin-left: 8.66666666667%;
}

.offset-by-two.vertical,
.offset-by-two.verticals {
    margin-left: 17.3333333333%;
}

.offset-by-three.vertical,
.offset-by-three.verticals {
    margin-left: 26%;
}

.offset-by-four.vertical,
.offset-by-four.verticals {
    margin-left: 34.6666666667%;
}

.offset-by-five.vertical,
.offset-by-five.verticals {
    margin-left: 43.3333333333%;
}

.offset-by-six.vertical,
.offset-by-six.verticals {
    margin-left: 52%;
}

.offset-by-seven.vertical,
.offset-by-seven.verticals {
    margin-left: 60.6666666667%;
}

.offset-by-eight.vertical,
.offset-by-eight.verticals {
    margin-left: 69.3333333333%;
}

.offset-by-nine.vertical,
.offset-by-nine.verticals {
    margin-left: 78.0%;
}

.offset-by-ten.vertical,
.offset-by-ten.verticals {
    margin-left: 86.6666666667%;
}

.offset-by-eleven.vertical,
.offset-by-eleven.verticals {
    margin-left: 95.3333333333%;
}

.offset-by-one-third.vertical,
.offset-by-one-third.verticals {
    margin-left: 34.6666666667%;
}

.offset-by-two-thirds.vertical,
.offset-by-two-thirds.verticals {
    margin-left: 69.3333333333%;
}

.offset-by-one-half.vertical,
.offset-by-one-half.verticals {
    margin-left: 52%;
}

/* Force Framework - default styles */
.fluid {
    width: 100%;
    max-width: auto;
}

.force-right {
    float: right;
}

.force-left {
    float: left;
}

.force-text-center {
    text-align: center;
}

.force-text-right {
    text-align: right;
}

.force-text-left {
    text-align: left;
}

.force-full-width {
    width: 100%;
    box-sizing: border-box;
}

.caption {
    margin: 5px 0 20px;
    font-weight: 500;
    font-size: 25px;
    padding: 7px 15px;
    background: rgba(64, 64, 64, 1);
    display: block;
    border-radius: 3px;
    color: #ffffff;
}

ul.inline {
    list-style: circle inside;
}

ul.inline li {
    display: inline-block;
}

/* Force Framework - padding, margin default styles */
.force-intro {
    margin-top: 100px;
}

.horizontal-pad-10 {
    padding: 10px 0;
}

.horizontal-pad-20 {
    padding: 20px 0;
}

.horizontal-pad-30 {
    padding: 30px 0;
}

.horizontal-pad-40 {
    padding: 40px 0;
}

.horizontal-pad-50 {
    padding: 50px 0;
}

.horizontal-pad-60 {
    padding: 60px 0;
}

.horizontal-pad-70 {
    padding: 70px 0;
}

.horizontal-pad-80 {
    padding: 80px 0;
}

.horizontal-mar-10 {
    margin: 10px 0;
}

.horizontal-mar-20 {
    margin: 20px 0;
}

.horizontal-mar-30 {
    margin: 30px 0;
}

.horizontal-mar-40 {
    margin: 40px 0;
}

.horizontal-mar-50 {
    margin: 50px 0;
}

.horizontal-mar-60 {
    margin: 60px 0;
}

.horizontal-mar-70 {
    margin: 70px 0;
}

.horizontal-mar-80 {
    margin: 80px 0;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.underline {
    text-decoration: underline;
}

.op9 {
    opacity: .9;
}

.op8 {
    opacity: .8;
}

.op7 {
    opacity: .7;
}

.op6 {
    opacity: .6;
}

.op5 {
    opacity: .5;
}

.op4 {
    opacity: .4;
}

.op3 {
    opacity: .3;
}

.op2 {
    opacity: .2;
}

.op1 {
    opacity: .1;
}

.op0 {
    opacity: 0;
}

.nodrop {
    cursor: no-drop;
}

/* Force Framework - navigation styles */
.header-force-framework {
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 9999;
    height: 100px;
    line-height: 100px;
    -webkit-box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
}

.header-force-framework .container {
    max-width: 1050px;
}

.header-force-framework .logo img {
    max-width: 90px;
    padding: 5px 0;
}

.primary-nav {
    height: 100px;
    line-height: 100px;
    text-align: right;
}

.primary-nav a {
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    color: #f68e38;
    text-decoration: none;
}

.primary-nav a:hover,
.primary-nav a:focus,
.primary-nav a.active {
    border-bottom: 2px solid #f68e38;
}

/* Force Framework - tables styles */
.force-table-responsive {
    max-height: 540px;
    overflow-y: scroll;
    font-size: 14px;
}

.force-table-responsive table {
    margin-bottom: 0;
}

.force-table-shadow {
    padding: 15px;
    -webkit-box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0px 35px 0 rgba(0, 0, 0, .1);
}

.force-table-compressed th,
.force-table-compressed td {
    padding: 5px 9px;
}

.force-table-responsive table th {
    background: #f6f6f6;
}

.force-table-responsive table tr:nth-child(2n) {
    background: #f9f9f9;
}

.force-table-responsive table tr:nth-child(2n+1) {
    background: #ffffff;
}

.force-table-responsive table th td:first-child,
.force-table-responsive table tr td:first-child {
    padding-left: 12px;
}

.force-table-shadow thead.dark {
    background: #676767;
    color: #ffffff;
}

.force-table-shadow thead.dark th,
.force-table-shadow thead.bg-orange th,
.force-table-shadow thead.bg-green th,
.force-table-shadow thead.bg-purple th,
.force-table-shadow thead.bg-red th,
.force-table-shadow thead.bg-violet th,
.force-table-shadow thead.bg-pink th,
.force-table-shadow thead.bg-alt-blue th {
    background: transparent !important;
}

/* Force Framework - buttons styles */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    display: inline-block;
    padding: 0 30px;
    color: #555;
    text-align: center;
    font-size: 11px;
    line-height: 38px;
    letter-spacing: 1px;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid #bbb;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: 300;
    margin: 0 4px 4px 0;
}

.btn,
.button {
    -webkit-transition: box-shadow ease-in-out .1s;
    -moz-transition: box-shadow ease-in-out .1s;
    -o-transition: box-shadow ease-in-out .1s;
    transition: box-shadow ease-in-out .1s;
    color: #373737;
    border: 1px solid rgba(0, 0, 0, .1);
    outline: 0;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus {
    outline: none;
    outline-offset: 0;
}

.button:focus,
.button:hover {
    border: 1px solid rgba(0, 0, 0, .2);
    outline: 0;
}

.btn {
    -webkit-transition: box-shadow ease-in-out .1s;
    -moz-transition: box-shadow ease-in-out .1s;
    -o-transition: box-shadow ease-in-out .1s;
    transition: box-shadow ease-in-out .1s;
    color: #ffffff;
}

.btn[class*='bg-']:hover {
    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.1);
}

.button.button-small {
    line-height: 18px;
    letter-spacing: .5px;
    font-size: 14px;
    padding: 5px 12px;
    margin-right: 7px;
    margin-bottom: 7px;
}

.button {
    line-height: 26px;
    font-size: 16px;
    padding: 5px 8px;
}

.button.button-large {
    line-height: 38px;
    font-size: 20px;
    letter-spacing: 1.2px;
    padding: 8px 20px;
}

.button {
    color: #e7e7e7;
    font-size: 16px;
    padding: 10px 26px;
    margin-right: 15px;
}

.button.button-blue {
    border-style: solid;
    border-width: 1px;
    border-color: #0670ff;
    border-radius: 5px;
    background-color: #0670ff;
    box-shadow: 0 20px 33px 0 rgba(6, 112, 255, .23);
}

.button.button-white {
    border-style: solid;
    border-width: 1px;
    border-color: #0670ff;
    border-radius: 5px;
    background-color: #fff;
    color: #0670ff;
}

.button.button-black {
    border-style: solid;
    border-width: 1px;
    border-color: #4d4141;
    border-radius: 5px;
    background-color: #4d4141;
    box-shadow: 0 20px 33px 0 rgba(77, 65, 65, .32);
}

.button.button-empty {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(223 223 223);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 20px 30px 0 rgb(77 65 65 / 7%);
    color: #3a2626;
}

.button.disabled {
    cursor: no-drop !important;
    background: #f7f5f5;
}

/* Force Framework - typography styles */
h1,
.h1 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700;
}

h2,
.h2 {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 700;
}

h3,
.h3 {
    font-size: 28px;
    line-height: 1.3;
    font-weight: 700;
}

h4,
.h4 {
    font-size: 24px;
    line-height: 1.35;
    font-weight: 500;
}

h5,
.h5 {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
}

h6,
.h6 {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 500;
}

.large {
    font-size: 120%;
}

.small {
    font-size: 90%;
}

.hide-this {
    display: none;
}

/* Force Framework - background, text styles */
.white {
    color: #ffffff;
}

.bg-white {
    background-color: #ffffff !important;
    color: #676767 !important;
}

.blue {
    color: #1ba1e5;
}

.bg-blue {
    background-color: #1ba1e5 !important;
    color: #ffffff;
}

.purple {
    color: #448aff;
}

.bg-purple {
    background-color: #448aff !important;
    color: #ffffff;
}

.red {
    color: #ff5252;
}

.bg-red {
    background-color: #ff5252 !important;
    color: #ffffff;
}

.orange {
    color: #ff6d00;
}

.bg-orange {
    background-color: #ff6d00 !important;
    color: #ffffff;
}

.green {
    color: #43a047;
}

.bg-green {
    background-color: #43a047 !important;
    color: #ffffff;
}

.violet {
    color: #9162e4;
}

.bg-violet {
    background-color: #9162e4 !important;
    color: #ffffff;
}

.pink {
    color: #ff6090;
}

.bg-pink {
    background-color: #ff6090 !important;
    color: #ffffff;
}

.alt-blue {
    color: #7c9cad;
}

.bg-alt-blue {
    background-color: #7c9cad !important;
    color: #ffffff;
}

.bg-pink:hover,
.bg-pink:focus,
.bg-pink:active,
.bg-purple:hover,
.bg-purple:focus,
.bg-purple:active,
.bg-orange:hover,
.bg-orange:focus,
.bg-orange:active,
.bg-violet:hover,
.bg-violet:focus,
.bg-violet:active,
.bg-alt-blue:hover,
.bg-alt-blue:focus,
.bg-alt-blue:active {
    color: #ffffff !important;
    -moz-box-shadow: inset 0 0 30px rgba(0, 0, 0, .1);
    -webkit-box-shadow: inset 0 0 30px rgba(0, 0, 0, .1);
    box-shadow: inset 0 0 30px rgba(0, 0, 0, .1);
}

/* Force Framework - force box styles */
.force-box {
    display: block;
    max-width: 1010px;
    border-radius: 3px;
    background: rgba(66, 63, 63, 0.04);
    margin: 30px auto;
    color: #adadad;
}

.force-box .force-box-container {
    padding: 40px;
    text-align: center;
}

.force-box ul {
    text-align: center;
}

.force-box .fit-image {
    max-width: 128px;
}

.force-box h3 {
    font-weight: 400;
}

/* Force Framework - badges styles */
.badge {
    padding: 6px 8px;
    font-size: 80%;
    letter-spacing: .1px;
    background: rgba(0, 0, 0, .05);
    margin: 2px;
    border-radius: 3px;
    cursor: pointer;
    float: left;
}

.badge.small {
    padding: 2px 4px;
    font-size: 70%;
}

.badge.large {
    padding: 8px 12px;
    font-size: 120%;
}

/* Force Framework - alert styles */
.alert {
    display: block;
    padding: 10px 15px;
    border-radius: 3px;
    margin: 10px 0;
    font-size: 18px;
}

.alert span {
    clear: left;
    display: block;
    font-size: 80%;
    margin-top: 4px;
}

/* Force Framework - card styles */
.card {
    padding: 10px 0;
}

.card.half {
    display: table-cell;
}

.card.one-third {
    display: table-cell;
}

.card.one-fourth {
    display: table-cell;
}

.card h4,
.card h5 {
    margin-bottom: 4px;
}

.card p {
    margin-bottom: 8px;
}

.card-data-container {
    width: calc(100% + 20px);
    margin-left: -10px;
}

.card .card-data {
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, .2);
    padding: 15px;
}

/* Force Framework - pagination styles */
.pagination {
    text-align: center;
    padding: 20px 0;
}

.pagination a {
    font-size: 12px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: #222;
    border: 1px solid rgba(0, 0, 0, .05);
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    text-decoration: none;
    border-radius: 50%;
    margin: 2px;
    transition: all 0.2s linear;
}

.pagination a:hover,
.pagination a.selected {
    color: #fff;
    background-color: #3498db;
}

.pagination.small a {
    font-size: 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.pagination.large a {
    font-size: 14px;
    width: 36px;
    height: 36px;
    line-height: 36px;
}

.pagination.orange a:hover,
.pagination.orange a.selected {
    background-color: #ff6d00;
}

.pagination.blue a:hover,
.pagination.blue a.selected {
    background-color: #1ba1e5;
}

.pagination.red a:hover,
.pagination.red a.selected {
    background-color: #ff5252;
}

.pagination.green a:hover,
.pagination.green a.selected {
    background-color: #43a047;
}

.pagination.violet a:hover,
.pagination.violet a.selected {
    background-color: #9162e4;
}

.pagination.pink a:hover,
.pagination.pink a.selected {
    background-color: #ff6090;
}

.pagination.purple a:hover,
.pagination.pink a.selected {
    background-color: #448aff;
}

.pagination.alt-blue a:hover,
.pagination.alt-blue a.selected {
    background-color: #7c9cad;
}

/* Force Framework - tab, content styles */
.force-tab {
    border-bottom: 1px solid #D1D1D1;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.force-tab li {
    list-style: none;
    display: inline-block;
    padding: 4px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    margin-left: 6px;
}

.force-tab li a {
    text-decoration: none;
    color: #262626;
    font-size: 106%;
}

.force-tab li a:hover,
.force-tab li a:focus {
    text-decoration: none;
}

.force-tab li.active,
.force-tab li:active,
.force-tab li:focus {
    background: #f6f6f6;
}

.force-tab li.active a,
.force-tab li:active a,
.force-tab li:focus a {
    color: #056efb;
}

.force-tab-content {
    padding: 5px;
}

.force-tab-content .listing {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #efefef;
    padding-top: 15px;
    padding-bottom: 15px;
}

.force-tab-content .listing:last-child {
    border-bottom: none;
}

/* Force Framework - input styles */
.input-group {
    display: block;
    margin-bottom: 10px;
    position: relative;
}

.input-group .input-field {
    width: 100%;
}

.pseudo-input-group {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
}

.pseudo-input-group .input-field {
    width: 48px;
    height: 48px;
    text-align: center;
}

.pseudo-search-group {
    width: 100%;
    margin-bottom: 0 !important;
    position: relative;
}

.pseudo-search-group svg {
    position: absolute;
    right: 10px;
    top: 12px;
}

.blue-link {
    color: #0670fe;
    cursor: pointer;
}

.blue-link svg {
    position: relative;
    top: 2px;
    margin-right: 3px;
}

.alert-green .input-field {
    border: 1px solid #3ec623 !important;
}

.alert-red .input-field {
    border: 1px solid #ff4040 !important;
}

/* Force Framework - breadcrumb styles */
.force-breadcrumb {
    background: #f6f6f6;
    padding: 3px 8px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 0;
}

.force-breadcrumb li {
    display: inline-block;
    font-size: 14px;
}

.force-breadcrumb li:after {
    content: "/";
    margin-left: 5px;
}

.force-breadcrumb li:last-child:after {
    content: "";
    margin-left: 0;
}

.force-breadcrumb li a {
    text-decoration: none;
}

/* Force Framework - custom styles */
.big-click-action {
    display: inline-block;
    width: 100%;
}

.big-click-action .click-action {
    width: auto;
    float: left;
    padding: 20px;
    border: 1px solid #D1D1D1;
    border-radius: 10px;
    margin-right: 15px;
    cursor: pointer;
}

.header-force-framework {
    height: 60px;
}

.blue-link {
    color: #0670fe;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
}

.blue-link svg {
    position: relative;
    top: 2px;
    margin-right: 3px;
}

.gray-pale {
    color: #526273;
}

.gray-dark {
    color: #444444;
}

.gray-light {
    color: #908e8e;
}

.fit-image {
    max-width: 100%;
    display: block;
    margin: auto;
}

/* Force Framework - code display styles */
.code-body-container {
    display: block;
}

.code-body {
    overflow: scroll;
}

.cool-layout {
    background: #f9f9f9;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
}

/* Force Framework - drop image here styles  */
.force-drop-here {
    padding: 35px 0;
    text-align: center;
    border: 3px dashed #d6d6d6;
    border-radius: 8px;
    text-align: center;
}

.force-drop-here picture img {
    max-width: 280px;
}

.output-images picture img {
    max-width: 60px;
}

.force-table table {
    border: none;
}

.border-dashed {
    padding: 0;
    text-align: center;
    border: 3px dashed #d6d6d6;
    border-radius: 8px;
    text-align: center;
}

.border-dashed picture img {
    width: 100%;
}

.border-dashed h4 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 24px;
    margin-bottom: 24px;
}

.border-dashed table {
    margin-bottom: 0;
}

.border-dashed table th,
.border-dashed table td {
    padding: 4px 10px;
    text-align: center;
    width: 33%;
}

.pseudo-upload-button {
    width: 100%;
    max-width: 280px;
    position: relative;
    padding: 10px 0px;
    height: 48px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
    margin-top: 20px;
}

.pseudo-upload-button input {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 278px;
    height: 46px;
    top: 0;
    cursor: pointer;
}

.border-dashed.output-images {
    padding: 20px;
    border: 0px dashed #056efb;
    background-color: #dfedff;
}