/* Dashboard styles */

@media only screen and (max-width: 1999px) {
    /* write styles upto 1999px */
}

@media only screen and (max-width: 1440px) {
    /* write styles upto 1440px */
}

@media only screen and (max-width: 1200px) {
    /* write styles upto 1200px */
}

@media only screen and (max-width: 768px) {

    /* write styles upto 768px */
    header.header-dashboard {
        width: 100%;
        height: 80px;
        position: relative;
        padding: 0;
    }

    .user-initials {
        right: 20px;
    }

    header.header-dashboard nav {
        display: none;
    }

    .dashboard .container {
        padding: 20px;
    }

    .settings-section,
    .dashboard-section {
        padding: 0;
    }

    .card-cover .card-listing {
        width: calc(50% - 10px);
        margin-right: 15x;
    }

    .card-cover .card-listing:nth-child(even) {
        margin-right: 0;
    }

    .stats-counter-box .verticals.six {
        margin-bottom: 15px;
    }

    .create-invoice {
        padding: 0;
    }

    .invoice-preview-section {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
}

@media only screen and (max-width: 549px) {
    /* write styles upto 549px */
}

@media only screen and (max-width: 419px) {
    /* write styles upto 419px */
}

@media only screen and (max-width: 360px) {
    /* write styles upto 360px */
}