/* Theme styles */

@media only screen and (max-width: 1999px) {
    /* write styles upto 1999px */
}

@media only screen and (max-width: 1440px) {
    /* write styles upto 1440px */
}

@media only screen and (max-width: 1200px) {
    /* write styles upto 1200px */
}

@media only screen and (max-width: 768px) {

    /* write styles upto 768px */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    header.header-dashboard {
        width: 100%;
        height: 68px;
        padding: 0;
        position: relative;
        z-index: 999;
    }

    header.header-dashboard nav {
        display: none;
    }

    header .logo-section {
        margin-top: 26px;
        margin-bottom: 26px;
    }

    .layout-common .layout-inner,
    .layout-form .layout-inner {
        padding: 20px;
    }

    .basic-layout {
        margin-top: -180px;
        margin-bottom: 50px;
    }

    .basic-layout .layout-form .horizontal {
        margin-bottom: 10px;
    }

    .basic-layout.app-index-layout {
        width: 100%;
        margin-top: -180px;
        margin-bottom: 40px;
        position: absolute;
        bottom: -32px;
    }

    .basic-layout.app-index-layout .layout-form .horizontal {
        margin-bottom: 0;
    }

    .dashboard .container,
    .dashboard-section,
    .settings-section {
        padding: 0;
    }

    .user-initials {
        width: 36px;
        height: 36px;
        font-size: 18px;
        line-height: 36px;
        top: 22px;
        right: 0;
    }

    .big-click-action .verticals {
        margin-left: 4%;
    }

    .big-click-action .verticals:last-child {
        margin-left: 0;
    }

    .big-click-action .click-action {
        padding: 8px 12px;
        margin-bottom: 10px;
        margin-right: 0;
    }

    .force-text-center .button {
        margin-right: 0;
        margin-left: 0;
    }

    .button-full:last-child {
        margin-bottom: 0;
    }

    .invoice-cover .table-cover .force-table-responsive {
        overflow-x: scroll;
    }

    .border-dashed {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 549px) {
    /* write styles upto 549px */
    .button {
        margin: auto;
    }
    .horizontal.margin-reset-top {
        margin-top: 20px !important;
    }
    .horizontal.margin-reset-top hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 419px) {
    /* write styles upto 419px */
    .pseudo-upload-button {
        max-width: 250px;
    }
    .pseudo-upload-button input {
        width: 248px;
    }
    .border-dashed.output-images {
        padding: 20px 0;
    }
    .border-dashed table th, .border-dashed table td, .download-btn {
        font-size: 13px;
    }
    .force-drop-here picture img {
        max-width: calc(100% - 30px);
    }
}

@media only screen and (max-width: 360px) {
    /* write styles upto 360px */
    .layout-common .layout-inner, .layout-form .layout-inner {
        padding: 20px 0;
    }
}