/* Theme styles */

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300&display=swap);

body {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 1.84;
}

/* basic styles */
a {
    color: #066efb;
    text-decoration: none;
}

b, strong {
    font-weight: 700;
}

a:active,
a:focus,
a:hover {
    color: #066efb;
    text-decoration: underline;
}

input[type=date]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=text]:focus,
input[type=url]:focus,
select:focus,
textarea:focus {
    border: 1px solid #056efb;
    box-shadow: 0 0 15px 0 rgba(6, 112, 255, .17);
}

/* nav, header, footer */
nav {
    margin-top: 40px;
}

header {
    background: #056efb;
    width: 100%;
    border-bottom: 5px solid #ffcd36;
    animation: color 20s infinite linear;
    position: relative;
    z-index: 1;
}
  
header .container {
    max-width: 1230px;
}

footer {
    background: #161d2c;
    padding-top: 100px;
    padding-bottom: 100px;
    color: #cbcbcb;
}

footer .horizontal {
    max-width: 1024px;
    margin: auto;
}

footer a {
    color: #cbcbcb;
}

footer a:active,
footer a:focus,
footer a:hover {
    color: #cbcbcb;
    border: none;
}

footer hr {
    border-top: 1px solid #2f2f2f;
}

header .logo-section {
    width: 140px;
    text-align: center;
    position: relative;
    float: left;
}
header .logo-section {
    margin-top: 28px;
    margin-bottom: 28px;
}
header .navi-section {
    float: right;
    margin-top: 28px;
    margin-bottom: 28px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
}
header .navi-section span {
    background-color: #ffffff;
    border-radius: 10px;
    color: #056efb;
    padding: 1px 5px;
    margin-left: 5px;
}
footer .logo-section {
    width: 140px;
    text-align: center;
    position: relative;
    max-width: 768px;
    margin: auto;
}
footer .logo-section {
    margin-bottom: 20px;
}
.fit-flag-india {
    width: 22px;
    position: relative;
    top: 3px;
    margin-left: 3px;
    margin-right: 3px;
}

/* basic layout */
.basic-layout {
    margin-top: -150px;
}

.basic-layout .layout-form .horizontal {
    margin-bottom: 30px;
}

.layout-cover {
    display: block;
    width: 100%;
    max-width: 1230px;
    margin: auto;
}

.layout-common .layout-inner,
.layout-form .layout-inner {
    padding: 70px 0;
}

.layout-inner {
    border-bottom: 1px solid #e4e4e4;
    padding: 50px 0;
}

.layout-inner:last-child {
    border-bottom: 0;
}

.layout-inner .horizontal {
    margin: auto;
}

/* force box */
.force-box {
    background: rgb(255 255 255);
    color: #000;
    margin-top: 140px;
}

.force-box .force-box-container {
    padding: 0;
}

.force-box .fit-image {
    margin-top: 40px;
    max-width: 96px;
}

/* big click action */
/*.app-index-layout .big-click-action .click-action, .basic-layout .big-click-action .click-action { width: 100%; padding: 0; margin-bottom: 15px; margin-right: 0;}
.app-index-layout .big-click-action .click-action:last-child, .basic-layout .big-click-action .click-action:last-child { margin-bottom: 0; margin-right: 0;} */
.basic-layout .layout-form .big-click-action .horizontal {
    margin-bottom: 0;
}

.big-click-action .verticals {
    margin-left: 4%;
}

.big-click-action .verticals:last-child {
    margin-left: 0;
}

.big-click-action .verticals.three {
    width: 22%;
    text-align: center;
}

.big-click-action .verticals.nine {
    width: 74%;
}

.big-click-action .verticals h5 {
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 0;
}

.big-click-action .verticals h6 {
    margin-left: 15px;
    margin-bottom: 0;
}

.big-click-action .verticals img {
    max-width: 64px;
    margin-top: 6px;
}

/* force pagination */
.pagination {
    padding: 0;
}

.pagination a {
    border-radius: 3px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-weight: 800;
    background-color: #f7f7f7;
}

.pagination a.selected,
.pagination a:hover {
    background-color: #056bf4;
}

/* other styles */
label.click-action {
    font-weight: 500;
}

label.click-action input[type=checkbox],
label.click-action input[type=radio] {
    margin-right: 5px;
}

.spacing-bullets {
    margin-right: 15px;
    margin-left: 15px;
}

.user-initials {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    text-align: center;
    color: #056efb;
    font-size: 24px;
    font-weight: 700;
    line-height: 48px;
    cursor: default;
    position: absolute;
    top: 27px;
    right: 20px;
}

.icon-center {
    width: 72px;
    height: 72px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}

.svg-over-section {
    text-align: center;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 20px 10px;
}

.svg-over-section h5 {
    margin-top: 17px;
    margin-bottom: 8px;
}

.svg-action {
    height: 42px;
    position: absolute;
    line-height: 42px;
    right: 8px;
}

.svg-action.remove-this {
    right: 26px;
    cursor: pointer;
}

.force-text-center .button {
    margin-right: 7.5px;
    margin-left: 7.5px;
}

.invoice-preview-section {
    display: block;
}

.invoice-cover {
    border: 1px solid #D1D1D1;
    padding: 10px;
    border-radius: 10px;
}

.invoice-cover .table-cover table {
    width: 100%;
    margin-bottom: 15px;
}

.invoice-cover .table-cover table thead tr th {
    background: #f6f6f6;
    padding: 12px 10px;
}

.invoice-cover .table-cover table tbody tr td {
    padding: 12px 10px;
    vertical-align: top;
}

.table-cover table thead tr th:last-child,
.table-cover table tbody tr td:last-child {
    text-align: right;
}
.horizontal.margin-reset-top hr:last-child {
    display: none;
}

.horizontal.margin-reset-top {
    margin-top: 80px !important;
}

.horizontal.margin-reset-bottom {
    margin-bottom: 80px !important;
}

.testimonial-section {
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0px 35px 0 rgb(0 0 0 / 5%);
    padding: 20px 30px;
    margin-bottom: 40px;
}

.testimonial-section h5 {
    margin-top: 8px;
    margin-bottom: 4px;
}

.testimonial-section picture {
    display: inline-block;
}

.testimonial-section picture img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

/* footer layout styles */
.footer-stats {
    padding: 26px 10px;
    border-radius: 5px;
    border: 3px dashed #d6d6d6;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
}
.footer-stats.count1 {
    border: 3px dashed #056efb;
    background-color: rgba(5,110,251,.2);
}
.footer-stats.count2 {
    border: 3px dashed #ffcd36;
    background-color: rgba(255,205,54,.2);
}
.footer-stats.count3 {
    border: 3px dashed #43a047;
    background-color: rgba(67,160,71,.2);
}

.footer-stats .h1 {
    font-size: 30px;
}

.download-btn {
    background-color: #056efb;
    border-radius: 16px;
    color: #ffffff;
    padding: 3px 9px;
    font-size: 14px;
}
.download-btn:hover ,
.download-btn:focus, 
.download-btn:active {
    background-color: #ffcd36;
    color: #000000;
    text-decoration: none;
} 

@keyframes color {
    0%   { background: #056efb; }
    25%  { background: #003bfc; }
    50%  { background: #0578fb; }
    75%  { background: #2447e2; }
    100% { background: #056efb; }
}

.card.full h5 {
    font-weight: 600;
}
.emoji-icon {
    font-size: 24px;
}